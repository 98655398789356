.box-category{
    display: flex;
    flex-direction: column;
    flex: 1;
    border-radius: 5px;
    padding: 12px;
    gap: 12px;
    background-color: #fff;
    box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.25);

    h3{
        font-weight: 700;
        font-size: 18px;
        color: #002B70;
        text-align: left;
        margin-bottom: 0px;
    }

    .box-category-img{
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        overflow: hidden;
        height: 126px;
        width: 100%;

        img{
            width: 100%;
            object-fit: cover;
        }

        a{
            text-decoration: none;
        }
    }

    .only-img-category{
        height: 223px;
        @media (max-width: 576px) {
            height: 254px;
        }

        img{
            object-fit: cover;
            width: 100%;
            height: 223px;
            @media (max-width: 576px) {
                height: 254px;
            }
        }
    }

    .box-category-products{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        gap: 12px;

        .box-category-product{
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            overflow: hidden;
            height: 85px;
            width: 100%;
            padding: 10px;
            border: 1px solid #D0D0D0;
            max-width: 85px;

            @media (max-width: 576px) {
                height: 116px;
                max-width: 116px !important;
            }

            img{
                max-width: 67px;
                max-height: 67px;
                object-fit: contain;
            }

            a{
                text-decoration: none;
            }
        }
    }

    .box-category-show-more{
        font-size: 12px;
        color: #002B70;
        text-decoration: underline;
    }
}

.js-boxCategoryCarousel.skeleton-loaderCarousel .boxCategoryCarousel .box-category{
    height: 310px !important;
    @media (max-width: 576px) {
        height: 341px !important;
    }

    .only-img-category{
        height: 240px !important;
        @media (max-width: 576px) {
            height: 254px !important;
        }
    }
}